$flex-h-spacing: 2em;
$flex-v-spacing: 10px;

.flex-grid {
    display: flex;
    justify-content: space-between;
    gap: $flex-h-spacing;
    padding-left: 50px;
    padding-right: 50px;
    width: 1366px;
    margin: auto;
    flex-wrap: wrap;
}

.flex-full-width {
    flex: 0 0 100%;
}

.flex-line-break {
    flex: 0 0 100%;
    height: 0;
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
  
    @return $number;
}

@function scale($min-val, $max-val, $min-width, $max-width) {
    $diff: strip-unit($max-val - $min-val);
    $divisor: strip-unit($max-width - $min-width);
    @return calc($min-val + $diff * (100vw - $min-width) / $divisor);
}

@media (min-width: 500.05px) and (max-width: 1366px) {
    .flex-grid {
        padding-left: scale(24px, 50px, 500px, 1366px);
        padding-right: scale(24px, 50px, 500px, 1366px);
        width: max(scale(500px, 1366px, 500px, 1366px), 880px);
        gap: scale(0.5em, $flex-h-spacing, 500px, 1366px);
    }
}
