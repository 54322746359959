@use 'react-md' as *;

.container {
    @include rmd-button-theme-update-var(outline-width, 4px);
    // @include rmd-button-theme-update-var(text-height, 4rem);
    // @include rmd-button-theme-update-var(text-min-width, 8rem);
    @include rmd-button-theme-update-var(text-min-width, 1fr);
    // @include rmd-button-theme-update-var(text-horizontal-padding, 4rem);
    // @include rmd-button-theme-update-var(outline-width, 100%);
    @include rmd-button-theme-update-var(text-border-radius, 2rem);
    @include rmd-button-theme-update-var(icon-border-radius, 1rem);
}

.large {
    @include rmd-button-theme-update-var(icon-size, $rmd-button-icon-size * 2);
    @include rmd-icon-theme-update-var(size, $rmd-icon-size * 2);
}