.listing--hidden {
    width: 280px;
    height: 0;
    border: none;
    padding: 0;
    margin: 0px auto;
    flex: 0 0 280px;
    
}

.listing {
    width: 280px;
    height: 490px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    background-color: white;
    padding: 20px;
    margin: 0px auto;
    flex: 0 0 280px;
}

.listing:hover.clickable {
    transform: scale(1.05);
    cursor: pointer;
}

.listing-img > img {
    border-top-right-radius: 50px;
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1/1;
}

.listing-title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}

.listing-body {
    font-size: 15px;
    text-align: center;
}

@media (max-width: 500px) {
    .listing {
        margin: 12px 0px;
        width: 100%;
        height: auto;
    }

    .listing-img {
        width: 100%;
        margin: 0px auto;
    }

    .listing-img > img {
        aspect-ratio: 6/4;
    }
}