body {
  /* margin: 0 -14px 0px -14px; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url(/src/img/background.jpg); */
  /* background-repeat: repeat-y; */
  /* background: url(/src/img/background.jpg) repeat-y; */
  /* background-size: cover; */
  /* height: 100%; */
  /* background-size: 20%;   */
  /* background-repeat:repeat; */
  /* background-attachment: fixed; */
  /* background-image: linear-gradient(to right, red , yellow); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section {
  width: calc(100vw - (100vw - 100%));
  /* height: 768px; */
  position: relative;
  /* margin-top: -1px; */
  /* margin-bottom: -1px; */
  /* padding-top: 50px; */
  overflow-y: visible;
  margin: 50px 0px;
}

.section-top {
  padding-top: 50px;
}

/* .background-img {
  background-repeat:no-repeat;
  background-size:cover;
  background-image:url(/src/img/background.jpg);
  background-position:center;
  opacity:1.0;
  height: inherit;
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;
} */

.flipped {
  transform:scale(1, -1);
}

#bg-img {
  background-repeat: no-repeat;
  background-size:cover;
  background-image:url(/src/img/background.jpg);
  background-position:center;
  opacity:1.0;
  position: sticky;
  top: 0;
  left: 0;
  width: calc(100vw - (100vw - 100%));
  height: 100vh;
  z-index: -1;
  margin-bottom: -100vh;
}